.c-green > svg {
  fill: var(--bs-green);
  height: 20px;
  width: 20px;
}

.c-blue > svg {
  fill: var(--bs-cyan);
  height: 20px;
  width: 20px;
}

.c-red > svg {
  fill: var(--bs-red);
  height: 20px;
  width: 20px;
}

.c-grey > svg {
  fill: var(--bs-gray);
  height: 20px;
  width: 20px;
}

.c-red > svg:hover,
.c-green > svg:hover,
.c-grey > svg:hover {
  fill: var(--bs-cyan);
}

.svg-success {
  fill: var(--bs-green);
}

.svg-danger {
  fill: var(--bs-red);
}

.svg-warning {
  fill: var(--bs-yellow);
}

.svg-primary {
  fill: var(--bs-cyan);
}

.svg-grey {
  fill: var(--bs-gray);
}

.svg05 {
  height: 0.5em;
  width: 0.5em;
}

.svg07 {
  height: 0.7em;
  width: 0.7em;
}

.svg09 {
  height: 0.9em;
  width: 0.9em;
}

.svg12 {
  height: 1.2em;
  width: 1.2em;
}

.svg14 {
  height: 1.4em;
  width: 1.4em;
}

.svg16 {
  height: 1.6em;
  width: 1.6em;
}

.svg18 {
  height: 1.8em;
  width: 1.8em;
}

.svg20 {
  height: 2em;
  width: 2em;
}

.h10 > svg {
  height: 10px;
  width: 10px;
}

.h15 > svg {
  height: 15px;
  width: 15px;
}

.h20 > svg {
  height: 20px;
  width: 20px;
}

.hov-svg > svg {
  width: 15px !important;
  height: 15px !important;
}

.hov-svg > svg:hover {
  color: var(--bs-green);
}

.hov-svg {
  transition: all 0.4s;
}

.hov-svg:hover {
  width: 3em !important;
  height: 3em !important;
}

.hov-svg-soft {
  transition: all 0.4s;
}

.hov-svg-soft:hover {
  width: 1.5em !important;
  height: 1.5em !important;
}
