.cont-400 {
  max-width: 400px;
  padding:  15px;
}

.cont-centered {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.line {
  width: 100% !important;
  display: flex !important;
  flex-direction: row !important;
  justify-content: center;
  align-items: center;
}

.bordered {
  border: 0.7px solid var( --pallete-blue);
  border-radius: 0.6em;
  margin: 0.4em;
  transition: 0.5s;
  color: var(  --bs-blue);
}

.bordered {
  color: var(  --c-gold) !important;
}

.bordered:hover {
  border: 0.7px solid var(--bs-cyan);
  border-radius: 0.6em;
  font-size: 1.1em;
  padding: 0.8em;
}

.bordered:hover {
  color: var(--bs-yellow) !important;
  font-weight: 500 !important;
}
