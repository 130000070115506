/* font size and weight */

.fw-100 {
  font-weight: 100 !important;
}

.fw-200 {
  font-weight: 200 !important;
}

.fw-300 {
  font-weight: 300 !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.fw-800 {
  font-weight: 800 !important;
}

.fw-900 {
  font-weight: 900 !important;
}

.fs-10 {
  font-size: 0.8em !important;
}

.fs-12 {
  font-size: 0.9em !important;
}

.fs-14 {
  font-size: 1em !important;
}

.fs-16 {
  font-size: 1.1em !important;
}

.fs-18 {
  font-size: 1.2em !important;
}

.fs-20 {
  font-size: 1.3em !important;
}

.fs-22 {
  font-size: 1.4em !important;
}

.fs-24 {
  font-size: 1.5em !important;
}

.fs-26 {
  font-size: 1.6em !important;
}

.fs-28 {
  font-size: 1.7em !important;
}

.fs-30 {
  font-size: 1.8em !important;
}

.fs-32 {
  font-size: 1.9em !important;
}

.fs-34 {
  font-size: 2em !important;
}

.fs-36 {
  font-size: 2.1em !important;
}

.fs-38 {
  font-size: 2.2em !important;
}

.fs-40 {
  font-size: 2.3em !important;
}

.fs-42 {
  font-size: 2.4em !important;
}

.fs-44 {
  font-size: 2.5em !important;
}

.fs-46 {
  font-size: 2.6em !important;
}

.fs-48 {
  font-size: 2.7em !important;
}

.fs-50 {
  font-size: 2.8em !important;
}

.fs-52 {
  font-size: 2.9em !important;
}

.fs-54 {
  font-size: 3em !important;
}

.ff-reg {
  font-family: var(--font-regular) !important;
}

.ff-soft {
  font-family: var(--font-soft) !important;
}

.fa-right {
  text-align: right !important;
}

.fa-center {
  text-align: center !important;
}

.fa-left {
  text-align: left !important;
}

.fa-justify {
  text-align: justify !important;
}

/* sizes */

.wp-100 {
  width: 100px !important;
}

.wp-200 {
  width: 200px !important;
}

.wp-250 {
  width: 250px !important;
}

.wp-300 {
  width: 300px !important;
}

.wp-400 {
  width: 400px !important;
}

.wp-500 {
  width: 500px !important;
}

.wp-600 {
  width: 600px !important;
}

.w-20 {
  width: 20% !important;
}

.w-30 {
  width: 30% !important;
}

.w-40 {
  width: 40% !important;
}

.w-50 {
  width: 50% !important;
}

.w-60 {
  width: 60% !important;
}

.w-70 {
  width: 70% !important;
}

.w-80 {
  width: 80% !important;
}

.w-90 {
  width: 90% !important;
}

.w-100 {
  width: 100% !important;
}

.wpmax-100 {
  max-width: 100px !important;
}

.wpmax-200 {
  max-width: 200px !important;
}

.wpmax-300 {
  max-width: 300px !important;
}

.wpmax-400 {
  max-width: 400px !important;
}

.wpmax-500 {
  max-width: 500px !important;
}

.wpmax-600 {
  max-width: 600px !important;
}

.wpmax-700 {
  max-width: 700px !important;
}

.wmax-10 {
  max-width: 10% !important;
}

.wmax-20 {
  max-width: 20% !important;
}

.wmax-30 {
  max-width: 30% !important;
}

.wmax-40 {
  max-width: 40% !important;
}

.wmax-50 {
  max-width: 50% !important;
}

.wmax-60 {
  max-width: 60% !important;
}

.wmax-70 {
  max-width: 70% !important;
}

.wmax-80 {
  max-width: 80% !important;
}

.wmax-90 {
  max-width: 90% !important;
}

.wpmin-100 {
  min-width: 100px !important;
}

.wpmin-200 {
  min-width: 200px !important;
}

.wpmin-300 {
  min-width: 300px !important;
}

.wpmin-400 {
  min-width: 400px !important;
}

.wpmin-500 {
  min-width: 500px !important;
}

.wpmin-600 {
  min-width: 600px !important;
}

.wpmin-700 {
  min-width: 700px !important;
}
