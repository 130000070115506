@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

:root {
  --font-regular: 'Open Sans', system-ui, -apple-system, sans-serif;
  --font-soft: 'Montserrat', system-ui, -apple-system, sans-serif;

  --palette-black: #000000 !important;
  --palette-darkest: #02071b !important;
  --palette-darker: #070d24 !important;
  --pallete-dark: #0d142f !important;
  --pallete-grafitte: #393e46 !important;
  --pallete-blue: #3a4776 !important;
  --pallete-bluer-transp: #000928b5 !important;
  --pallete-bluer: var(--bs-blue) !important;
  --pallete-light-blue: #007fd4 !important;
  --pallete-yellow: #f3c82e !important;
  --pallete-gold: #d1bb57 !important;
  --pallete-gray: #cccccc !important;
  --pallete-grey: #f0f0f0 !important;
  --pallete-white: #ffffff !important;

  --bg-overlay-dark: rgba(0, 0, 0, 0.6) !important;
  --bg-overlay-darker: rgba(0, 0, 0, 0.8) !important;
  --bg-overlay-light: rgba(255, 255, 255, 0.6) !important;
  --bg-overlay-lighter: rgba(255, 255, 255, 0.8) !important;

  --c-black: var(--palette-black) !important;
  --c-darkest: var(--palette-darkest) !important;
  --c-darker: var(--palette-darker) !important;
  --c-dark: var(--pallete-dark) !important;
  --c-graffite: var(--pallete-grafitte) !important;
  --c-gray: var(--pallete-gray) !important;
  --c-blue: var(--pallete-blue) !important;
  --c-bluer: var(--pallete-bluer) !important;
  --c-link: var(--pallete-light-blue) !important;
  --c-colored: var(--pallete-yellow) !important;
  --c-gold: var(--pallete-gold) !important;
  --c-light: var(--pallete-grey) !important;
  --c-white: var(--pallete-white) !important;

  --menu-color: var(--c-light) !important;
  --navbar-bg: var(--c-dark) !important;
  --navbar-color: var(--c-light) !important;

  --footer-bg: var(--c-darkest) !important;
  --footer-color: var(--c-light) !important;

  --button-bg: var(--c-colored) !important;
  --button-color: var(--c-dark) !important;
  --button-hover-bg: var(--c-blue) !important;
  --button-hover-color: var(--c-white) !important;

  --bg: var(--c-graffite) !important;
  --text: var(--c-light) !important;
  --text-graffite: var(--c-graffite) !important;
  --text-gray: var(--c-gray) !important;
  --text-contrast: var(--c-colored) !important;
  --text-contrast-light: var(--c-gold) !important;

  --color-dark: var(--c-darker) !important;
  --color-light: var(--c-light) !important;

  --TEST-BORDER: var(--c-light) !important;

  --bg-overlay: var(--bg-overlay-dark) !important;
  --bg-overlay-stronger: var(--bg-overlay-darker) !important;

  --menu-bg: var(--c-darker) !important;
  --menu-color: var(--c-light) !important;
  --navbar-bg: var(--c-darkest) !important;
  --navbar-color: var(--c-light) !important;

  --button-bg: var(--c-colored) !important;
  --button-color: var(--c-dark) !important;
  --button-hover-bg: var(--c-blue) !important;
  --button-hover-color: var(--c-white) !important;
}

.bgjustice {
  background-image: url('../img/justicebg.jpg');
  background-size: cover;
  background-position: center;
}

.bgmedico {
  background-image: url('../img/medicobg.jpg');
  background-size: cover;
  background-position: center;
}

.bgsaude {
  background-image: url('../img/pillsbg.jpg');
  background-size: cover;
  background-position: center;
}

.bgdigital {
  background-image: url('../img/bubblebg.jpg');
  background-size: cover;
  background-position: center;
}

/* pallete colors */

.cp-black {
  color: var(--palette-black) !important;
}

.cp-darkest {
  color: var(--palette-darkest) !important;
}

.cp-darker {
  color: var(--palette-darker) !important;
}

.cp-dark {
  color: var(--pallete-dark) !important;
}

.cp-graffite {
  color: var(--pallete-grafitte) !important;
}

.cp-gray {
  color: var(--pallete-gray) !important;
}

.cp-blue {
  color: var(--pallete-blue) !important;
}

.cp-bluer {
  color: var(--pallete-bluer) !important;
}

.cp-link {
  color: var(--pallete-light-blue) !important;
}

.cp-colored {
  color: var(--pallete-yellow) !important;
}

.cp-gold {
  color: var(--pallete-gold) !important;
}

.cp-light {
  color: var(--pallete-grey) !important;
}

.cp-white {
  color: var(--pallete-white) !important;
}

/* mouse-point */

.mouse-pointer:hover {
  cursor: pointer;
}

html {
  scroll-behavior: smooth;
}

* {
  margin: 0;
  padding: 0;
}

body {
  font-family: var(--font-regular);
  color: var(--text);
  background-color: var(--c-darker);
}

.f-soft {
  font-family: var(--font-soft);
}

/* pages 404 and 500 */

.home-logo {
  width: 50%;
  max-width: 500px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.no_under {
  text-decoration: none;
}

/* create classes for the colors */

.bw-bg {
  background-color: var(--bg);
}

.bw-bg-overlay {
  background-color: var(--bg-overlay);
}

.bw-bg-overlay-stronger {
  background-color: var(--bg-overlay-stronger);
}

.bw-text {
  color: var(--text);
}

.bw-text-contrast {
  color: var(--text-contrast);
}

.bw-text-contrast-light {
  color: var(--text-contrast-light);
}

.bw-menu-bg {
  background-color: var(--menu-bg);
}

.bw-menu-color {
  color: var(--menu-color);
}

.bw-navbar-bg {
  background-color: var(--navbar-bg);
}

.bw-navbar-color {
  color: var(--navbar-color);
}

.bw-footer-bg {
  background-color: var(--footer-bg);
}

.bw-footer-color {
  color: var(--footer-color);
}

.bw-button-bg {
  background-color: var(--button-bg);
}

.bw-button-color {
  color: var(--button-color);
}

.bw-button-hover-bg {
  background-color: var(--button-hover-bg);
}

.bw-button-hover-color {
  color: var(--button-hover-color);
}

/*  */

a {
  color: var(--c-link) !important;
  text-decoration: none;
}

footer {
  font-size: 0.8em;
  background-color: var(--footer-bg);
  color: var(--footer-color);
  padding: 10px 20px;
  bottom: 0;
  width: 100%;

  /* background: rgb(0, 0, 0);
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(38, 35, 64, 1) 15%,
    rgba(0, 0, 0, 1) 100%
  ); */
}

footer a {
  color: var(--c-gold) !important;
}

.hov-yellow:hover {
  color: var(--c-colored) !important;
}

.fc-gold {
  color: var(--c-gold);
}

.fc-light {
  color: var(--c-light);
}

.fc-dark {
  color: var(--c-dark);
}

.fc-gray {
  color: var(--c-gray);
}

.bg-banner {
  width: 100% !important;
  background-color: var(--c-darker);
}

.bg-banner-grad {
  background: rgb(0, 0, 0);
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(38, 35, 64, 1) 49%,
    rgba(0, 0, 0, 1) 100%
  );
}

.containerbox {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.containerbox-main,
.containerbox-main-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  /* margin: 10px !important; */
}

.navbar-100 {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--navbar-bg);
  color: var(--navbar-color);
  width: 100%;
  padding: 20px;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--navbar-bg);
  color: var(--navbar-color);
  width: 100%;
  max-width: 1100px;
  top: 0;
}

.navbar-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.navbar-logo img {
  width: 300px;
  height: auto;
  padding: 5px;
  object-fit: cover;
}

.navbar-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.9em;
}

.navbar-menu > .dropdown > button {
  /* background-color: var(--menu-bg); */
  color: var(--menu-color);
  border: none;
  padding: 5px 10px;
  margin: 0 3px;
  cursor: pointer;
}

.navbar-menu > .dropdown > .dropdown-toggle::after {
  display: none;
}

.navbar-menu > .dropdown > .dropdown-menu {
  min-width: fit-content !important;
  background-color: var(--c-dark);
  color: var(--menu-color);
}

.navbar-menu > .dropdown > .dropdown-menu a {
  color: var(--menu-color) !important;
}

.navbar-menu > .dropdown > .dropdown-menu a:hover {
  background-color: var(--c-dark) !important;
  color: var(--c-gold) !important;
}

.navbar-item {
  margin: 0 3px;
  padding: 5px;
  color: #fff;
  text-decoration: none;
}

main {
  display: flex;
  flex-direction: row;
  justify-content: justify;
  align-items: flex-start;
  width: 100%;
  min-height: 100vh;

  /* max-width: 1100px; */
  /* margin: 30px 10px !important; */
  /* position: absolute; */
  /* border: 1px solid var(--TEST-BORDER); */
}

.bg-banner {
  width: 100% !important;
  background-color: var(--c-darker);
}

.bg-banner-grad {
  background: rgb(0, 0, 0);
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(38, 35, 64, 1) 49%,
    rgba(0, 0, 0, 1) 100%
  );
}

.container-1100 {
  width: 100%;
  max-width: 1100px;
  margin: auto;
}

.gorow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.sidebar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 20%;
  padding: 10px;
  left: 0;
  /* border: 1px solid var(--TEST-BORDER); */
}

/* menu  */
.sp-menu-text {
  margin-left: 5px;
  padding: 5px;
  font-size: 0.9em;
}

/* cardbox */
.bw-section-cards {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.bw-cardbox {
  /* background-color: var(--bg); */
  font-family: var(--font-regular);
  width: 340px !important;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.bw-cardbox.card {
  /* background-color: var(--c-dark);
  color: var(--c-light); */
  padding: 12px;
  border: none;
  border-radius: 5px;
}

.bw-cardbox .card-header {
  /* margin-top: 5px; */
  font-family: var(--font-soft);
  /* color: var(--c-colored); */
}

.bw-cardbox .card-title,
.bw-cardbox .card-subtitle {
  margin-top: 5px;
  margin-bottom: 10px;
}

.bw-cardbox .card-title {
  font-family: var(--font-soft);
}

/*  */

.bg-black-900 {
  background-color: #000000c3 !important;
}

.bg-bluer-transp {
  background-color: var(--pallete-bluer-transp) !important;
}

/* landing banner */

.landing-banner {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  /* background-color: var(--bg); */
  /* padding: 20px; */
  position: relative;
}

.landing-banner-image {
  width: 60%;
  /* height: auto; */
}

.landing-banner-image-container {
  width: 100%;
  display: flex;
  position: relative;
}

.landing-banner-text-container {
  display: flex;
  flex-direction: column;
  justify-content: right;
  align-items: center;
  width: 50%;
  padding: 20px;
  margin-left: 30%;
  position: absolute;
  z-index: 0;
  background-color: var(--bg-overlay-darker);
  color: var(--c-light);
  opacity: 0;
  transform: translateY(50px); /* Começa deslocada */
  transition: all 2s ease-in-out;
}

.landing-banner-text-container.visible {
  opacity: 1;
  transform: translateY(0); /* Movimenta para a posição inicial */
}

.landing-banner-slogan {
  font-family: var(--font-soft);
  font-size: 2em;
  background-color: var(--bg-overlay-darker);
  color: var(--c-colored);
  padding: 15px;
  position: absolute;
  width: 100%;
  text-align: center;
  z-index: 1;
  bottom: 0;
  font-weight: 600;
}
/*  */

#test-smooth {
  scroll-behavior: smooth;
}

@media screen and (max-width: 700px) {
  .landing-banner {
    flex-direction: column !important;
  }

  .landing-banner-image {
    width: 80%;
  }

  .landing-banner-image-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    flex-direction: column;
  }

  .landing-banner-text-container {
    /* position: relative; */
    margin-left: 0;
    margin-right: 0;
    margin-top: 50%;
    margin-bottom: auto;
    width: 100%;
  }

  .landing-banner-slogan {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .navbar-logo {
    height: 80px;
  }
  .navbar-logo img {
    width: 250px;
  }
}

@media screen and (max-width: 950px) {

  .gocol950 {
    display: flex;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    text-align: center !important;
  }
}

@media screen and (max-width: 630px) {
  .gocol630 {
    display: flex;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    text-align: center !important;
    width: 100% !important;
  }

  .gocol630 > .wpmin-200 {
    display: flex;
    width: 100% !important;
  }
}

@media screen and (max-width: 1100px) {
  .bw-section-cards {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
  }

  .cardbox-main,
  .bw-cardbox {
    width: 98% !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
  }
}

@media screen and (prefers-reduced-motion: reduce) {
  html {
    scroll-behavior: auto;
  }
}
