:root {
  --bs-blue: #1e337c;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #aa0000;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffbb00;
  --bs-green: #2cbe00;
  --bs-teal: #00a372;
  --bs-cyan: #007fd4;
  --bs-black: #000;
  --bs-white: #fff;

  --bs-gray-100: #f8f9fa; 
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  
}

.c-blue {
  color: var(--bs-blue);
}

.c-indigo {
  color: var(--bs-indigo);
}

.c-purple {
  color: var(--bs-purple);
}

.c-pink {
  color: var(--bs-pink);
}

.c-red {
  color: var(--bs-red);
}

.c-orange {
  color: var(--bs-orange);
}

.c-yellow {
  color: var(--bs-yellow);
}

.c-green {
  color: var(--bs-green);
}

.c-teal {
  color: var(--bs-teal);
}

.c-cyan {
  color: var(--bs-cyan);
}

.c-black {
  color: var(--bs-black);
}

.c-white {
  color: var(--bs-white);
}
